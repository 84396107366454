<template>
  <div>
    <HeaderProject />
    <div class="container">
      <div class="row content">
        <div class="col-12 project-header d-flex align-items-center">
          <div class="project-header-content">
            <p class="mb-0">MR | VR</p>
            <h1>ASICS: Play the<br/> Unplayable</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row content">
        <div class="col-12 px-0">
          <div class="video-container">
            <video height="720" width="1280" class="project-video" ref="videoPlayer" :poster="require('@/assets/images/project-asics-1.png')">
              <source src="https://visyon360-website.s3.eu-west-1.amazonaws.com/VIDEO_CASE/CASE_ASICS.mp4" type="video/mp4" class="w-100">
            </video>
            <img class="play-button" :src="require('@/assets/images/play-button.png')" v-on:click="playVideo"/>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row content">
        <div class="col-12 col-md-6">The Challenge</div>
        <div class="col-12 col-md-6">Asics approached us to create an activation in stores to promote their new padel rackets. An innovative range of products designed to offer a better control of the ball in any situation of the game.</div>
        <div class="col-12">
          <img :src="require('@/assets/images/project-asics-2.png')" class="w-100 img-fluid" alt=""/>
        </div>
        <div class="col-12 col-md-6">The Story</div>
        <div class="col-12 col-md-6">As the product offered more precision in every move, we decided to confront the players with a higher challenge. ‘Play the Unplayable’ is a mixed reality experience that transports players into a futuristic court to compete against an expert paddle robot, a tough opponent trained to throw balls as a professional player.
          <br/><br/>
          Furthermore, the floor of the court had a peculiarity that nobody expected. During the game, the ground changed its shape, creating waves that changed the ball's trajectory.
          <br/><br/>
          The matches were played in a physical space approximately three metres wide, but in virtual reality the player saw a court almost ten metres wide. And this was a huge challenge for our developers.
          <br/><br/>
          “The most difficult part for us was dealing with the size of the court.The virtual space was three times larger than the physical space. So we had to train the robot to throw balls only in a playable area, considering that the ground generated waves that could change the direction of the ball”  David Cornellana, Visyon developer.
        </div>
        <div class="col-12 col-md-6">
          <img :src="require('@/assets/images/project-asics-3.png')" class="w-100 img-fluid" alt=""/>
        </div>
        <div class="col-12 col-md-6">
          <img :src="require('@/assets/images/project-asics-4.png')" class="w-100 img-fluid" alt=""/>
        </div>
        <div class="col-12 col-md-6 offset-md-6">To attract the public's attention, we placed an LED screen outside the shop showing the player inside the virtual environment playing the game.
          <br/><br/>
          In addition, we involved two luxury guests in the experience: the world champions Bela and Lima. Aside from introducing the game, the two paddle superstars set a challenge to the audience: the one who scored more points would win a personalised masterclass with them.
          <br/><br/>
          “Play the Unplayable” was implemented in three Asics stores (Madrid, Valencia and Barcelona) attracting more than 500 participants and being covered in the sector's main media channels.
        </div>
        <div class="col-12">
          <img :src="require('@/assets/images/project-asics-5.png')" class="w-100 img-fluid" alt=""/>
        </div>
      </div>
    </div>
  </div>
  <ScrollTopButton />
</template>

<script>
import HeaderProject from "../components/Header/header.project.vue";
import ScrollTopButton from "../components/commons/scroll-top/ScrollTopButton";
export default {
  components: {
    HeaderProject,
    ScrollTopButton
  },
  mounted() {
    document.documentElement.style.scrollBehavior = "auto";
    setTimeout(() => window.scrollTo(0, 0), 5);
    setTimeout(
      () => (document.documentElement.style.scrollBehavior = "smooth"),
      5
    );
  },
  methods: {
    playVideo: function(e){
      this.$refs.videoPlayer.play();
      e.target.classList.add('d-none');
      this.$refs.videoPlayer.setAttribute("controls", "controls");
    }
  }
};
</script>

<style lang="scss" scoped>
.video-container{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .project-video{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .play-button{
    position: relative;
    cursor: pointer;
  }
}
.project-header{
  height: 70vh;
  .project-header-content{
    text-transform: uppercase;
    color: #f3f3f3;
    p{
      font-size: 16px;
      font-family: abc_diatypemedium, sans-serif;
    }
    h1{
      font-size: 50px;
      line-height: 70px;
      font-family: abc_diatypemedium, sans-serif;
      @media(min-width: 768px) {
        font-size: 88px;
        line-height: 96px;
      }
    }
  }
}
.content{
  font-size: 24px;
  line-height: 32px;
  > *{
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
</style>