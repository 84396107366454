<template>
  <div
    class="
      container-fluid
      block3
      justify-content-center
      align-items-center
      d-none
      d-lg-flex
    "
    id="about"
  >
    <div class="block3-text">
      <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-interval="false">
        <div class="carousel-inner">
          <div class="carousel-item active item1">
            <p class="text-start text-sm-center text-uppercase">
              Born from the burning desire<br />
              to make each challenge a<br />
              source of creation.
            </p>
          </div>
          <div class="carousel-item item2">
            <div class="text-container text-start text-sm-center">
              <p>We get the most out of XR technologies and make incredible things happen.</p>
              <p>We are creative thinkers and makers with a passion for XR technologies.</p>
              <p>We make incredible things happen.</p>
              <p>We augment the world.</p>
              <p>We envision new realities to immerse people in meaningful virtual experiences.</p>
              <p>We craft your presence in new realities.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="center-arrow" :class="{'rotated': rotated}">
        <svg @click="rotate()" :class="{'rotated': rotated}"
          class="arrow mt-5 d-flex"
          width="37"
          height="31"
          viewBox="0 0 27 21"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g stroke="#000" stroke-width="3" fill="none" fill-rule="evenodd">
            <path d="m15.5 2 8.5 8.5-8.5 8.5M24 10.5H0" />
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Block3",
  data() {
    return {
      rotated: false
    }
  },
  mounted() {
    let c = document.getElementById("carouselExampleSlidesOnly");
    new window.bootstrap.Carousel(c);
  },
  methods: {
    rotate(){
      let c = document.getElementById("carouselExampleSlidesOnly");
      let instance = window.bootstrap.Carousel.getInstance(c);
      // console.log(this.rotated);
      instance.to(this.rotated ? 0 : 1);
      this.rotated = !this.rotated;
    }
  }
};
</script>

<style lang="scss" scoped>
.block3 {
  background-image: url("../../assets/images/bg-block3.png");
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  position: relative;
  height: 100vh;
  @media (max-width: 767px) {
    background-image: url("../../assets/images/bg-block3-mobile.png");
    background-position: center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    position: relative;
    height: 150vh;
  }
}
.arrow{
  cursor: pointer;
  transition: .4s all ease-in-out;

  &.rotated {
    transform: rotateY(180deg);
  }
}
.item1 {
  @media(min-width: 768px) {
    font-size: 60px;
  }
  // text-shadow: 1px 1px rgba(255,255,255,0.5);
  text-shadow:1px 1px rgba(255, 255, 255, 0.1);
  font-family: abc_diatypemedium, sans-serif;
  font-size: 48px;
  line-height: 72px;
  letter-spacing: -1.6px;
  color: #151519;
}
.item2{
  line-height: 24px;
  font-size: 18px;
  color: #151519;
}
.text-container {
  font-size: 28px;
  line-height: 36px;
  min-height: 231px;
  & p {
    margin-bottom: 2px !important;
  }
}

.carousel-item {
  padding-left: 20px;
  padding-right: 20px;

  @media(min-width: 768px) {
    padding-left: 150px;
    padding-right: 150px;
  }
}

.center-arrow {
  bottom: 0;
  display: inline-block;
  height: 120px;
  left: 50%;
  position: relative;
  right: 50%;
  width: 120px;

  &:hover {
    animation: pulse-right 3s infinite;
    position: relative;
  }

  &.rotated:hover {
    animation: pulse-left 3s infinite;
    position: relative;
  }

  @keyframes pulse-right {
    0% {
      left: 50%;
    }
    50% {
      left: 53%;
    }
    100% {
      left: 50%;
    }
  }

  @keyframes pulse-left {
    0% {
      left: 50%;
    }
    50% {
      left: 47%;
    }
    100% {
      left: 50%;
    }
  }
}

</style>