<template>
  <div class="container text-light">
    <h1 class="my-5 mx-md-5">Clients</h1>
    <div class="clients mx-md-5 row mt-5 mb-2">
      <div
        class="client col-4 col-md-2 my-2"
        v-for="(client, index) in clients"
        :key="index"
      >
        <img :src="client.img" alt="" class="img-fluid w-100" />
      </div>
    </div>
    <h1 class="my-5 mx-md-5">Awards</h1>
    <div
      class="awards mx-md-5 row mt-5 row-cols-md-5 row-cols-3"
    >
      <div class="award col my-2" v-for="(award, index) in awards" :key="index">
        <img class="img-fluid w-100" :src="award.img" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Clients",
  data() {
    let clients = [];
    let awards = [];
    for (let i = 1; i <= 36; i++) {
      clients.push({
        id: i,
        title: "nike",
        img: require("@/assets/images/logo-" + i + ".png"),
      });
    }
    for (let i = 1; i <= 5; i++) {
      awards.push({
        id: i,
        title: "nike",
        img: require("@/assets/images/award-" + i + ".png"),
      });
    }
    return { clients: clients, awards: awards };
    /*return {
      clients: [
        { id: 1, title: "nike", img: "http://fpoimg.com/200x150?text=logo" },
        { id: 2, title: "fike", img: "" },
        { id: 3, title: "rike", img: "" },
        { id: 4, title: "wike", img: "" },
        { id: 5, title: "nike", img: "" },
        { id: 6, title: "fike", img: "" },
        { id: 7, title: "rike", img: "" },
        { id: 8, title: "wike", img: "" },
        { id: 9, title: "nike", img: "" },
        { id: 10, title: "fike", img: "" },
        { id: 11, title: "rike", img: "" },
        { id: 12, title: "wike", img: "" },
      ],
      awards: [
        { id: 1, title: "aw1", img: require("@/assets/images/award-1.png") },
        { id: 2, title: "aw2", img: require("@/assets/images/award-2.png") },
        { id: 3, title: "aw3", img: require("@/assets/images/award-3.png") },
        { id: 4, title: "aw4", img: require("@/assets/images/award-4.png") },
        { id: 5, title: "aw5", img: require("@/assets/images/award-5.png") },
      ],
    };*/
  },
};
</script>

<style lang="scss" scoped>
.client {
  img {
    border-radius: 8px;
  }
}
.awards {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 120px;
}
.award {
  img {
    border-radius: 8px;
  }
}
</style>