<template>
  <div>
    <HeaderProject />

    <div class="container" id="main">
      <div class="row intro">
        <div class="col">
          <h1 class="text-center">Creative immersive agency for Brands</h1>
          <p class="text-center">Brand agency capable of conceptualizing, creating and implementing strategic immersive campaigns </p>
          <div class="Rectangle"></div>
        </div>
      </div>
    </div>
    <template v-for="(project, index) in projects" :key="index">
      <div class="container-fluid project" :class="index % 2 === 0 ? 'img-left' : 'img-right'">
        <div class="container">
          <div v-if="project.name === 'SEAT'" class="project-main">
            <div class="col">
              <h1 class="text-center">Enterprise</h1>
              <p class="text-center">Long-term relationships with national and international companies in the development of XR solutions in onboarding, training, learning, sales and operations processes.</p>
              <div class="Rectangle"></div>
            </div>
          </div>
          <div v-if="project.name === 'SOROLLA TRHOUGHT LIGHT'" class="project-main">
            <div class="col">
              <h1 class="text-center">Entertainment & Cultural immersive experiences</h1>
              <p class="text-center">Leader of interactive and immersive experiences in museums, exhibitions and events</p>
              <div class="Rectangle"></div>
            </div>
          </div>
          <div class="row mt-5 project-row py-5" :class="{'mb-5': index !== projects.length-1}">
            <div class="col-12 col-md-6">
              <img :src="require('@/assets/images/projects/'+project.img)" alt="" class="img-fluid"/>
            </div>
            <div class="col-12 col-md-6">
              <div class="project-text">
                <div class="project-tags">{{project.tags}}</div>
                <div class="project-name">{{project.name}}</div>
                <div class="project-desc" v-html="project.desc"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
  <ScrollTopButton />
</template>
<style lang="scss" scoped>
.img-left {
  background-color: rgba(0,0,0,0.2);
  .project-row {
    flex-direction: row;
  }
}
.img-right{

  .project-row{
    flex-direction: row-reverse;
  }
}
.project-row{

}
#main {
  margin-top: 100px;
}

.project-main{
  padding-top: 100px;
}

.project{
}
.project-text{
  .project-tags{
    text-transform: uppercase;
    font-size: 14px;
  }
  .project-name{
    font-size: 46px;
    text-transform: uppercase;
  }
  .project-description{
    font-size: 16px;
  }
}
.Rectangle {
  width: 58px;
  height: 4px;
  margin: 10px auto;
  background-color: #f3f3f3;
}
</style>
<script>
import HeaderProject from "../components/Header/header.project.vue";
import works from "../assets/works.json";
import ScrollTopButton from "../components/commons/scroll-top/ScrollTopButton";
export default {
  components: {
    HeaderProject,
    ScrollTopButton
  },
  mounted() {
    document.documentElement.style.scrollBehavior = "auto";
    setTimeout(() => window.scrollTo(0, 0), 5);
    setTimeout(
      () => (document.documentElement.style.scrollBehavior = "smooth"),
      5
    );
  },
  data() {
    return {
      works: works,
      projects: works
    };
  }
};
</script>