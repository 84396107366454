<template>
  <div>
    <HeaderProject />
    <div class="container">
      <div class="row content">
        <div class="col-12 project-header d-flex align-items-center">
          <div class="project-header-content">
            <p class="mb-0">VR</p>
            <h1>
              Red Bull BC One<br />
              World Final
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row content">
        <div class="col-12 px-0">
          <div class="video-container">
            <video
              height="720"
              width="1280"
              class="project-video"
              ref="videoPlayer"
              :poster="require('@/assets/images/project-redbull-1.png')"
            >
              <source
                src="https://visyon360-website.s3.eu-west-1.amazonaws.com/VIDEO_CASE/CASE_REDBULL.mp4"
                type="video/mp4"
                class="w-100"
              />
            </video>
            <img
              class="play-button"
              :src="require('@/assets/images/play-button.png')"
              v-on:click="playVideo"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row content">
        <div class="col-12 col-md-6">The Challenge</div>
        <div class="col-12 col-md-6">
          Red Bull BC One is the most prestigious one-on-one B-Boy and B-Girl competition
          in the world. Every year, thousands of dancers across the globe vie for a chance
          to represent at the World Final. At 2016’s final in Nagoya, Japan, Red Bull and
          Google partnered with Visyon to go beyond the ordinary and create something that
          could celebrate breakers’ individuality in a new and immersive way.
        </div>
        <div class="col-12">
          <img
            :src="require('@/assets/images/project-redbull-2.png')"
            class="w-100 img-fluid"
            alt=""
          />
        </div>
        <div class="col-12 col-md-6">The Story</div>
        <div class="col-12 col-md-6">
          Back in the days, breakdance and graffiti were two art forms that used to be
          fully intertwined: one would announce the other. But with time and
          professionalisation, both art forms diverged over the years. Red Bull wanted to
          reunite them, giving breakdance enthusiasts a brand-new immersive virtual
          reality (VR) experience during the event.
          <br /><br />
          Breakdancer Menno and graffiti artist Steak teamed up to create the first
          virtual artwork inspired by a B-boy's breakdance style: The Breakpiece. As Menno
          danced, Steak moved right with him—and instead of spray cans, Steak painted
          three-dimensional brush strokes in VR thanks to Google's VR painting tool Tilt
          Brush.
        </div>
        <div class="col-12 col-md-6">
          <img
            :src="require('@/assets/images/project-redbull-3.png')"
            class="w-100 img-fluid"
            alt=""
          />
        </div>
        <div class="col-12 col-md-6">
          <img
            :src="require('@/assets/images/project-redbull-4.png')"
            class="w-100 img-fluid"
            alt=""
          />
        </div>
        <div class="col-12 col-md-6 offset-md-6">
          The outcome? Over 5,000 visitors got to see the result of Steak’s stunning
          artwork on the massive 40m x 40m holographic mesh screen we assembled at the
          event. Hundreds of visitors were also able to get inspired by Menno’s moves and
          create and share their very own ‘breakpiece’ at one of the three Tilt Brush
          stations we set up.
          <br /><br />
          For breakdance and street art fans who couldn’t attend, the experience was also
          available on an app for the VR headset Daydream where users were able to explore
          the artwork and get curated details about artists and their works.
          <br /><br />
          Over the course of two days, we mashed up two different art forms: dance and
          street art, inspiring artists around the world to work in 3D.
        </div>
        <div class="col-12">
          <img
            :src="require('@/assets/images/project-redbull-5.png')"
            class="w-100 img-fluid"
            alt=""
          />
        </div>
        <div class="col-12 col-md-6">
          <img
            :src="require('@/assets/images/project-redbull-6.png')"
            class="w-100 img-fluid"
            alt=""
          />
        </div>
        <div class="col-12 col-md-6">
          <img
            :src="require('@/assets/images/project-redbull-7.png')"
            class="w-100 img-fluid"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
  <ScrollTopButton />
</template>

<script>
  import HeaderProject from "../components/Header/header.project.vue";
  import ScrollTopButton from "../components/commons/scroll-top/ScrollTopButton";
  export default {
    components: {
      HeaderProject,
      ScrollTopButton,
    },
    mounted() {
      document.documentElement.style.scrollBehavior = "auto";
      setTimeout(() => window.scrollTo(0, 0), 5);
      setTimeout(() => (document.documentElement.style.scrollBehavior = "smooth"), 5);
    },
    methods: {
      playVideo: function (e) {
        this.$refs.videoPlayer.play();
        e.target.classList.add("d-none");
        this.$refs.videoPlayer.setAttribute("controls", "controls");
      },
    },
  };
</script>

<style lang="scss" scoped>
  .video-container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .project-video {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .play-button {
      position: relative;
      cursor: pointer;
    }
  }
  .project-header {
    height: 70vh;
    .project-header-content {
      text-transform: uppercase;
      color: #f3f3f3;
      p {
        font-size: 16px;
        font-family: abc_diatypemedium, sans-serif;
      }
      h1 {
        font-size: 50px;
        line-height: 70px;
        font-family: abc_diatypemedium, sans-serif;
        @media (min-width: 768px) {
          font-size: 88px;
          line-height: 96px;
        }
      }
    }
  }
  .content {
    font-size: 24px;
    line-height: 32px;
    > * {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
</style>
