<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');
</script>
<style>
* {
  user-select: none;
}
html,
body,
.height-100 {  
  height: 100%;
  width: 100%;
  background-color: black;
}

body {
  overflow-x: hidden;
}

#app{
  -moz-background-size: cover;
  -o-background-size: cover;
  -webkit-background-size: cover;
  background-image: url("./assets/images/bg-project.png");
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color: #fff;
}
</style>