<template>
  <div class="text-light home-content">
    <video-player :options="detectIOS()? videoOptionsMobile : videoOptions" @click="openInNewTab('https://www.youtube.com/watch?v=8ZM9wTalBW4')" style="cursor: pointer;"/>
    
    <!-- <a @click="openInlineVideo" target="_blank">
      <img :src="require('@/assets/images/bg-ios.png')" class="is-ios" />
    </a> -->
    <!-- <div
      class="container-fluid"
      id="overlay"
    >
      <div 
        class="title col-12 col-sm-10"
      >
        WE'RE AN IMMERSIVE CREATIVE COMPANY
      </div>

      <div @click="openInlineVideo">
        <div class="mask">
          <div class="cta">Watch showreel</div>
          <svg
            width="27"
            height="21"
            viewBox="0 0 27 21"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              stroke="#151519"
              stroke-width="3"
              fill="none"
              fill-rule="evenodd"
            >
              <path d="m15.5 2 8.5 8.5-8.5 8.5M24 10.5H0" />
            </g>
          </svg>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { commonMethods } from './../../mixins/common.ts';
import VideoPlayer from '@/components/commons/videojs/VideoPlayer.vue';


export default {
  components: {
    VideoPlayer
  },
  data: function () {
    return {
      elementVisible: true,
      videoOptionsMobile: {
        html5: {
          hls: {
            overrideNative: false
          },
          nativeVideoTracks: true,
          nativeAudioTracks: true,
          nativeTextTracks: true
        },
        controls: true,
        autoplay: true,
        preload: "auto",
        loop: true,
        muted: true,
        sources: [
         {
          src: 'https://media.thetavideoapi.com/video_ttd47sh2murqmwk8ei7fjmeucq/master.m3u8',
          type: 'application/vnd.apple.mpegurl'
         } 
        ]
      },
      videoOptions: {
        autoplay: true,
        controls: false,
        preload:"auto",
        loop: true,
        muted: true,
        poster: require('@/assets/images/play_thumbnail.png'),
        // sources: [
        //   {
        //     src: 'https://visyon360-website.s3.eu-west-1.amazonaws.com/hls/master.m3u8',
        //       type: 'application/x-mpegURL'
        //   }
        // ]
        sources: [
         {
          src: 'https://media.thetavideoapi.com/video_ttd47sh2murqmwk8ei7fjmeucq/master.m3u8',
          type: 'application/vnd.apple.mpegurl'
         } 
        ]
      }
    };
  },
  methods: {
    openInNewTab(url) {
      window.open(url, '_blank', 'noreferrer');
    },
    openInlineVideo() {
      this.$emit("openInlineVideo");
    },
    // onEnd() {
    //   if (this.showingIntro) {
    //     this.$refs.videoPlayer.pause();
    //     this.$refs.source.src = require("@/assets/videos/Shots_Loop_v2.mp4");
    //     this.$refs.videoPlayer.load();
    //     this.$refs.videoPlayer.loop = true;
    //     this.$refs.videoPlayer.play();
    //     this.showingIntro = false;
    //   } else {
    //     this.$refs.videoPlayer.play();
    //   }
    // },
    ...commonMethods
  },
};


</script>

<style lang="scss" scoped>
.home-content {
  height: 100vh;
  width: 100vw;
}
@media (max-width: 768px) {
  .home-content {
    height: auto !important;
  }
}
#video1 {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
@supports (-webkit-touch-callout: none) {
  .home-content {
    min-height: 20vh;
    overflow-x: hidden;
  }
  .title {
    margin-left: 16px;
    margin-top: 35px;
    width: calc( 100% - 16px);
  }
}
#overlay {
  position: absolute;
  height: 100vh;
  top: 0;
  .title {
    margin-left: 40px;
    margin-top: 100px;
    font-family: abc_diatypemedium, sans-serif;
    font-size: 40px;
    font-weight: 500;
    line-height: 1.09;
    letter-spacing: -1px;
    color: #f3f3f3;
    
  }

  @media (max-width: 768px) {
    height: 100% !important;
    
    .title {
      display: none;
      // position: absolute;
      // margin-left: 16px;
      // margin-top: 35px;
      // width: auto !important;
      // font-family: abc_diatypebold, sans-serif;
      // font-size: 40px;
      // font-weight: 500;
      // line-height: 1.14;
      // letter-spacing: -1px;
      // color: #f3f3f3; 
    }
    .mask {
      transform: scale(.75);
      position: absolute;
      bottom: 0 !important; 
      right: 0 !important; 
    }
  }

  .mask:hover {
    & .cta {
      text-decoration: underline;
    }
  }

  .mask {
    & .cta {
      width: 100%;
      text-align: center;
      font-size: 18px;
      color: #151519;
      text-decoration: none;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 10px;
    }
    cursor: pointer;
    background-image: url("../../assets/images/block1-circle.png");
    background-position: center;
    background-repeat: no-repeat;
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;
    position: absolute;
    bottom: 6%;
    right: 4%;
    width: 120px;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: abc_diatypemcedium, sans-serif;
    div {
      font-size: 24px;
      text-align: center;
      color: #000;
    }
  }
}
.filete {
  height: 8px;
  background-image: linear-gradient(to left, #ff5848 1%, #ff57c6 99%);
}
</style>
