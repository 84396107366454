<template>
  <div>
    <HeaderProject />
    <div class="container">
      <div class="row content">
        <div class="col-12 project-header d-flex align-items-center">
          <div class="project-header-content">
            <p class="mb-0">EXPANDING 3D ENVIRONMENT</p>
            <h1>Metahype</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row content">
        <div class="col-12 px-0">
          <div class="video-container">
            <video height="720" width="1280" class="project-video" ref="videoPlayer" :poster="require('@/assets/images/project-metahype-1.png')">
              <source src="https://visyon360-website.s3.eu-west-1.amazonaws.com/VIDEO_CASE/CASE_METAHYPE.mp4" type="video/mp4" class="w-100">
            </video>
            <img class="play-button" :src="require('@/assets/images/play-button.png')" v-on:click="playVideo"/>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row content">
        <div class="col-12 col-md-6">The Challenge</div>
        <div class="col-12 col-md-6">After the success of the e-Garage platform, CUPRA wanted to take its digital strategy even further to be placed as a technological reference for the automotive industry.
          <br/><br/>
          As an innovative brand that understands the potential of the new web 3.0, they wanted to connect with younger generations and enable digital collaboration and co-creation with brands, content creators, and digital communities in a completely interactive and immersive ecosystem.
        </div>
        <div class="col-12">
          <img :src="require('@/assets/images/project-metahype-2.png')" class="w-100 img-fluid" alt=""/>
        </div>
        <div class="col-12 col-md-6">The Story</div>
        <div class="col-12 col-md-6">METAHYPE is the first metaverse experience created by CUPRA in partnership with VISYON and a creative and commercial solution for future-facing brands.
          <br/><br/>
          An ever-expanding 3D environment divided into districts where brands, creators, and communities connect through next-generation social experiences and interact with gamified and immersive content.
        </div>
        <div class="col-12 col-md-6">
          <img :src="require('@/assets/images/project-metahype-3.png')" class="w-100 img-fluid" alt=""/>
        </div>
        <div class="col-12 col-md-6">
          <img :src="require('@/assets/images/project-metahype-4.png')" class="w-100 img-fluid" alt=""/>
        </div>
        <div class="col-12 col-md-6">Digital Venues for Brands and Creators</div>
        <div class="col-12 col-md-6">In METAHYPE, brands can own districts and build their own photorealistic virtual spaces there. These portions of virtual land can hold digital events, showrooms, installations, galleries of digital assets, private parties, conferences, and almost any imaginable digital or immersive activation.
          <br/><br/>
          Every new partner in METAHYPE has access to a new district space. Brands can choose a specific district configuration from a list of options and customize it according to their visual style and purpose.
          <br/><br/>
          As in real-life venues, they earn the right to use the space for holding brand experiences and activations aimed at their workforce, communities, and fans.
        </div>
        <div class="col-12">
          <img :src="require('@/assets/images/project-metahype-5.png')" class="w-100 img-fluid" alt=""/>
        </div>
        <div class="col-12 col-md-6">Rethinking Digital Identity</div>
        <div class="col-12 col-md-6">Virtual identity is a big concern for digital users that aim to express themselves and explore the new creative possibilities of avatar customization. It also allows visitors to identify with others and create communities around common ideas and styles.
          <br/><br/>
          In this new platform, people can freely express their identity with full-body expressive avatars that are able to interact with other characters through face animations, dances, and movements that can be triggered by activating different options on the emotion interface.
        </div>
        <div class="col-12 col-md-6">
          <img :src="require('@/assets/images/project-metahype-6.png')" class="w-100 img-fluid" alt=""/>
        </div>
        <div class="col-12 col-md-6">
          <img :src="require('@/assets/images/project-metahype-7.png')" class="w-100 img-fluid" alt=""/>
        </div>
        <div class="col-12 col-md-6">It is All About Connection</div>
        <div class="col-12 col-md-6">Socialization is a key part of METAHYPE. Here, people can meet up, connect with their friends, and explore with them every corner of the virtual island. Also, users can engage, talk to each other, and connect with new friends using text, voice or video chat.</div>
        <div class="col-12">
          <img :src="require('@/assets/images/project-metahype-8.png')" class="w-100 img-fluid" alt=""/>
        </div>
        <div class="col-12 col-md-6">
          <img :src="require('@/assets/images/project-metahype-9.png')" class="w-100 img-fluid" alt=""/>
        </div>
        <div class="col-12 col-md-6">
          <img :src="require('@/assets/images/project-metahype-10.png')" class="w-100 img-fluid" alt=""/>
        </div>
        <div class="col-12 col-md-6">High Quality Rendering and Visual effects</div>
        <div class="col-12 col-md-6">METAHYPE offers amazing tools and functionalities for brands to showcase their products in the best way possible. Our virtual space supports spectacular 3D product visualization in ray traced rendering quality.
          <br/><br/>
          In addition, we can integrate custom-designed effects and interactions into special events and launches to generate unforgettable moments.
        </div>
        <div class="col-12 col-md-6">A Multilingual Experience</div>
        <div class="col-12 col-md-6">METAHYPE wants to gather people and brands from every corner of the world, that is why the platform is conceived as a multilingual environment. It currently supports five languages, but it is expected that this list will grow over time along with the platform community. </div>
        <div class="col-12">
          <img :src="require('@/assets/images/project-metahype-11.png')" class="w-100 img-fluid" alt=""/>
        </div>
      </div>
    </div>
  </div>
  <ScrollTopButton />
</template>

<script>
import HeaderProject from "../components/Header/header.project.vue";
import ScrollTopButton from "../components/commons/scroll-top/ScrollTopButton";
export default {
  components: {
    HeaderProject,
    ScrollTopButton
  },
  mounted() {
    document.documentElement.style.scrollBehavior = "auto";
    setTimeout(() => window.scrollTo(0, 0), 5);
    setTimeout(
      () => (document.documentElement.style.scrollBehavior = "smooth"),
      5
    );
  },
  methods: {
    playVideo: function(e){
      this.$refs.videoPlayer.play();
      e.target.classList.add('d-none');
      this.$refs.videoPlayer.setAttribute("controls", "controls");
    }
  }
};
</script>

<style lang="scss" scoped>
.video-container{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .project-video{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .play-button{
    position: relative;
    cursor: pointer;
  }
}
.project-header{
  height: 70vh;
  .project-header-content{
    text-transform: uppercase;
    color: #f3f3f3;
    p{
      font-size: 16px;
      font-family: abc_diatypemedium, sans-serif;
    }
    h1{
      font-size: 50px;
      line-height: 70px;
      font-family: abc_diatypemedium, sans-serif;
      @media(min-width: 768px) {
        font-size: 88px;
        line-height: 96px;
      }
    }
  }
}
.content{
  font-size: 24px;
  line-height: 32px;
  > *{
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
</style>