import { createApp } from "vue";
import router from "./router";
import i18n from "./i18n";
import App from "./App.vue";
import "./assets/fonts.css";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";

// Google Analitycs Tracking Id 
const TRACKING_ID = 'G-74RNPM9QG7'

window.addEventListener('CookiebotOnAccept', () => {
      // eslint-disable-next-line no-undef
      if (Cookiebot.consent.statistics)
            {
                var script = document.createElement('script');
                script.onload = () => {
                    // eslint-disable-next-line no-undef
                    gtag("js", new Date());
                    // eslint-disable-next-line no-undef
                    gtag("config", TRACKING_ID, {
                        'page_location': document.location,
                        'page_title': document.title,
                        'send_page_view': true
                    });
                };
                script.src = "https://www.googletagmanager.com/gtag/js?id="+ TRACKING_ID;
                document.head.appendChild(script);
          } 
}, false);

createApp(App).use(i18n).use(router).mount("#app");
