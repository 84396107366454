<template>
  <div>
    <div class="d-none d-sm-block">
      <HeaderProject />
    </div>
    <div class="d-block d-sm-none">
      <HeaderMobile />
    </div>
    <div class="w-100">
    <div class="container-fluid pt-5 cookies">
      <div class="row content">
        <div class="col-12 col-md-6 title">Cookies policy</div>
        <div class="col-12 col-md-6 space-right">
          <p>
            This Cookie Policy is an integral part of the legal texts of
            www.visyion360.com (hereinafter the “Website”). Accessing and
            browsing on the Website, or using its services, implies acceptance
            of the legal texts of the Website.
          </p>
          <p>
            Estudio Bay, S.L.U. (hereinafter “VISYON” or the “Provider”), a
            commercial company with registered office at c/Lepanto, 350, eighth
            floor, 08025 Barcelona with CIF B65684813, informs you that it uses
            Cookies or other files with similar functionality such as Pixels,
            Tags or online identifiers (hereinafter “Cookies”) in order to
            facilitate your browsing.
          </p>
          <p>
            In any case, we inform you that the Provider is responsible for the
            Cookies and for the processing of the data obtained through its own
            Cookies and those of third parties, deciding the purpose, content
            and use of the processing of the information collected.
          </p>
        </div>
      </div>
      <div class="row content">
        <div class="col-12 col-md-6 title">Definition and uses of cookies</div>
        <div class="col-12 col-md-6">
          <p>
            Cookies are small text files that are store don a user’s device when
            they visit websites. They can be used, for example, to recognize you
            as a user, obtain information about your browsing habits or
            personalize the way content is displayed.
          </p>
          <p>Our specific uses of these technologies are described below.</p>
        </div>
      </div>
      <div class="row content">
        <div class="col-12 col-md-6 title">
          Information on the type of cookies we use and their purpose
        </div>
        <div class="col-12 col-md-6">
          <p>The Website uses the following cookies:</p>
          <p>
            Technical cookies: These allow the user to browse the Website and
            use the different options or services offered. These are essential
            for the operation of the Website, allowing access to the different
            functions that it has. These cookies are necessary for the operation
            of the website and therefore cannot be deactivated.
          </p>
          <p>
            Preference cookies: These allow the user to remember information to
            access the service with certain characteristics that may
            differentiate their experience from that of other users, such as
            language, number of results to be displayed, etc. These cookies will
            be deactivated by default.
          </p>
          <div>TABLA</div>
          <p>
            Analytical cookies: These allow us to quantify the number of users
            and thus perform the measurement and statistical analysis of the use
            made by users of the service offered. For this purpose, your
            navigation on our website is analyses in order to improve the
            products or services we offer you. These cookies will be deactivated
            by default.
          </p>
          <div>TABLA</div>
          <p>
            Advertising cookies: These allow us to analyze your internet
            browsing habits so that we can show you advertising related to your
            browsing profile. These cookies will be disabled by default.
          </p>
        </div>
      </div>
      <div class="row content">
        <div class="col-12 col-md-6 title">
          Setting of cookies used on the website
        </div>
        <div class="col-12 col-md-6">
          <p>
            You can set cookies that are not strictly necessary for navigation
            through the setting panel. Please note that if you reject the use of
            cookies, some of the web services may be affected.
          </p>
        </div>
      </div>
      <div class="row content">
        <div class="col-12 col-md-6 title">
          How to prevent the installation of cookies?
        </div>
        <div class="col-12 col-md-6">
          <p>
            Your browser can be set to automatically reject cookies. In these
            links, you will find the necessary information to carry out this
            configuration. En estos enlaces, aparece la información necesaria
            para llevar a cabo dicha configuración:
            <br />
            <ul class="list-links d-flex flex-column px-0">
                  <a class="link" href=""> Firefox </a>
            <a class="link" href=""> Google Chrome </a>
            <a class="link" href=""> Internet Explorer </a>
            <a class="link" href=""> Safari </a>
            </ul>
          </p>
        </div>
      </div>
       <div class="row content">
        <div class="col-12 col-md-6 title">
          International Transfers
        </div>
        <div class="col-12 col-md-6">
          <p>
            You can find out about any transfers to third countries made by the third parties identified in this cookie policy in their respective policies (see links provided in the “These are the third party providers” section).
          </p>
        </div>
      </div>
      <div class="row content">
        <div class="col-12 col-md-6 title">
          Configuration of cookies on other platforms.
        </div>
        <div class="col-12 col-md-6">
            <p>
                Like computer browsers, mobile devices browsers allow you to make changes to your privacy options or settings to disable or delete cookies.
            </p>
            <p>
                If you want to change your privacy options, follow the instructions specified by your mobile browser developer.
            </p>
          <p>
           Below you will find some examples of link that will guide you to modify the privacy setting on your mobile device:
            <br />
            <ul class="list-links d-flex flex-column px-0">
                <a class="link" href=""> Safari para IOS</a>
                <a class="link" href=""> Chrome para Android </a>
            </ul>
          </p>
        </div>
      </div>
    </div>
    </div>
    <FooterLinks />
  </div>
</template>

<script>
import HeaderProject from "../components/Header/header.project.vue";
import HeaderMobile from "../components/Header/header.mobile.vue";
import FooterLinks from "../components/Footer/FooterLinks.vue";
export default {
  components: {
    HeaderProject,
    HeaderMobile,
    FooterLinks,
  },
    mounted() {
    document.documentElement.style.scrollBehavior = "auto";
    setTimeout(() => window.scrollTo(0, 0), 5);
    setTimeout(
      () => (document.documentElement.style.scrollBehavior = "smooth"),
      5
    );
  },
};
</script>

<style lang="scss" scoped>
.project-header {
  height: 70vh;
  .project-header-content {
    text-transform: uppercase;
    color: #f3f3f3;
    p {
      font-size: 16px;
      font-family: abc_diatypemedium, sans-serif;
    }
    h1 {
      font-size: 88px;
      line-height: 96px;
      font-family: abc_diatypemedium, sans-serif;
    }
  }
}
.content {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.18px;
  font-family: abc_diatypemedium, sans-serif;

  > * {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .title {
    font-size: 36px;
    line-height: 1.33;
    letter-spacing: -0.4px;
    padding-left: 38px;

    @media (max-width: 767px) {
      padding-left: initial;
    }
  }
  @media (max-width: 767px) {
    div {
      padding-left: 0;
    }
  }
  .link {
    color: #ff56c6;
    text-decoration: none;
  }
}

.list-links {
  list-style: none;
  a {
    text-decoration: none;
  }
}
.cookies {
  margin-top: 10vh;

  padding-left: 30px;
  padding-right: 30px;
  .space-right {
    padding-right: 38px;
  }
  @media (max-width: 768px) {
    .space-right {
      padding: 0;
    }
  }
}
</style>