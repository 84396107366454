<template>
  <div class="filete w-100"></div>
  <nav class="navbar navbar-light navbar-expand-lg position-absolute w-100">
    <div class="container-fluid d-flex justify-content-end">
      <router-link to="/">
        <img :src="require('@/assets/images/close-x.png')" alt="" />
      </router-link>
    </div>
  </nav>
</template>

<script>
export default {
  name: "HeaderMobile",
  props: {},
};
</script>

<style scoped>
.filete {
  height: 8px;
  background-image: linear-gradient(to left, #ff5848 1%, #ff57c6 99%);
}
</style>
