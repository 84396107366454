<template>
  <div>
    <HeaderProject />
    <div class="container">
      <div class="row content">
        <div class="col-12 project-header d-flex align-items-center">
          <div class="project-header-content">
            <p class="mb-0">AR | IMMERSIVE STORYTELLING</p>
            <h1>Girona Art <br/>Museum</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row content">
        <div class="col-12 px-0">
          <div class="video-container">
            <video height="720" width="1280" class="project-video" ref="videoPlayer" :poster="require('@/assets/images/project-girona-1.png')">
              <source src="https://visyon360-website.s3.eu-west-1.amazonaws.com/VIDEO_CASE/CASE_GIRONA.mp4" type="video/mp4" class="w-100">
            </video>
            <img class="play-button" :src="require('@/assets/images/play-button.png')" v-on:click="playVideo"/>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row content">
        <div class="col-12 col-md-6">The Challenge</div>
        <div class="col-12 col-md-6">At a time when so many kids are disengaged with learning, Girona Art Museum sought to find new opportunities to modernise the visiting experience and better connect with its youngest audience. The goal? Enabling children to interactively view and experience the exhibit content through play. To achieve this, Visyon created an augmented reality (AR) app which brings art pieces to life, providing a novel experience that showcases the potential of immersive storytelling within education.</div>
        <div class="col-12">
          <img :src="require('@/assets/images/project-girona-2.png')" class="w-100 img-fluid" alt=""/>
        </div>
        <div class="col-12 col-md-6">The Story</div>
        <div class="col-12 col-md-6">Through location-based and augmented reality technologies, the Girona Art Museum AR app transforms the animals within the exhibit’s art pieces into three-dimensional animated characters, and takes children aged 8-12 on an immersive quest to help the Lioness of Girona find her friends. On this adventure, they will collect characters, solve puzzles and find a lot of interesting facts about the exhibit.
          <br/><br/>
          Using the AR app, the Lioness invites kids to follow a trail of clues through the museum, tracking footprints of the lovable characters, and leading them to locations where they are hiding. Once spotted, they’re brought to life in augmented reality as the Lioness shares the story behind the art piece. Children can stand next to the characters and take photos as keepsakes using their phone or tablet.
          <br/><br/>
          A unique experience that blends education and entertainment, taking children’s visiting experience to new heights, encouraging them to explore and learn, firing their imagination and enabling them to have a unique cultural experience.
        </div>
        <div class="col-12 col-md-6">
          <img :src="require('@/assets/images/project-girona-3.png')" class="w-100 img-fluid" alt=""/>
        </div>
        <div class="col-12 col-md-6">
          <img :src="require('@/assets/images/project-girona-4.png')" class="w-100 img-fluid" alt=""/>
        </div>
        <div class="col-12">
          <img :src="require('@/assets/images/project-girona-5.png')" class="w-100 img-fluid" alt=""/>
        </div>
      </div>
    </div>
  </div>
  <ScrollTopButton />
</template>

<script>
import HeaderProject from "../components/Header/header.project.vue";
import ScrollTopButton from "../components/commons/scroll-top/ScrollTopButton";
export default {
  components: {
    HeaderProject,
    ScrollTopButton
  },
  mounted() {
    document.documentElement.style.scrollBehavior = "auto";
    setTimeout(() => window.scrollTo(0, 0), 5);
    setTimeout(
      () => (document.documentElement.style.scrollBehavior = "smooth"),
      5
    );
  },
  methods: {
    playVideo: function(e){
      this.$refs.videoPlayer.play();
      e.target.classList.add('d-none');
      this.$refs.videoPlayer.setAttribute("controls", "controls");
    }
  }
};
</script>

<style lang="scss" scoped>
.video-container{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .project-video{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .play-button{
    position: relative;
    cursor: pointer;
  }
}
.project-header{
  height: 70vh;
  .project-header-content{
    text-transform: uppercase;
    color: #f3f3f3;
    p{
      font-size: 16px;
      font-family: abc_diatypemedium, sans-serif;
    }
    h1{
      font-size: 50px;
      line-height: 70px;
      font-family: abc_diatypemedium, sans-serif;
      @media(min-width: 768px) {
        font-size: 88px;
        line-height: 96px;
      }
    }
  }
}
.content{
  font-size: 24px;
  line-height: 32px;
  > *{
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
</style>