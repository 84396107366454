<template>
  <Header />
  <VideoSlider @openInlineVideo="onOpenInlineVideo" ref="videoSlider" />
  <Block2 />
  <Block3 />
  <ClientsAwards />
  <Footer />
  <ScrollTopButton />
  <VideoSliderMain v-if="showVideoReel" @close="onCloseInlineVideo" />
</template>

<script>
import Header from "@/components/Header/header.header.vue";
import VideoSlider from "@/components/HomeContent/VideoSlider.vue";
import Block2 from "@/components/HomeContent/Block2.vue";
import Block3 from "@/components/HomeContent/Block3.vue";
// import HomeProjectList from "@/components/Projects/HomeProjectList.vue";
import ClientsAwards from "@/components/HomeContent/ClientsAwards.vue";
import Footer from "@/components/Footer/Footer.vue";
import ScrollTopButton from "@/components/commons/scroll-top/ScrollTopButton.vue";
import VideoSliderMain from "@/components/HomeContent/VideoSliderMain.vue";
import { commonMethods } from './../mixins/common.ts';

export default {
  name: "Home",
  components: {
    VideoSliderMain,
    Header,
    VideoSlider,
    Block2,
    Block3,
    ClientsAwards,
    ScrollTopButton,
    Footer,
  },
  data: function () {
    return {
      showVideoReel: false,
    };
  },
  methods: {
    onOpenInlineVideo() {
      this.showVideoReel = true;
    },
    onCloseInlineVideo() {
      this.showVideoReel = false;
    },
  },
  mounted() {
    this.$refs.videoSlider.showingIntro = commonMethods.detectMobile() ? false : true;
    const scrolButton = document.getElementById("buttonToTop");
    scrolButton.style.display = "none";
    function scrollFunction() {
      const navbar = document.getElementById("navbar");
      const scrolButton = document.getElementById("buttonToTop");

      if (scrolButton) {
        if (document.documentElement.scrollTop > 100){
          scrolButton.style.display = "initial";
        } else {
          scrolButton.style.display = "none";
        }
      }
      
      if (!navbar){
        return;
      }
      
      if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1 ) {
        navbar.style.background = "rgba(80, 30, 39,.5)";
        navbar.style.backdropFilter = "blur(15px)";
      } else {
        navbar.style.background = "none";
        navbar.style.backdropFilter = "blur(0)";
      }
    }
    document.documentElement.style.scrollBehavior = "auto";
    setTimeout(
      () => (document.documentElement.style.scrollBehavior = "smooth"),
      5
    );
    window.onscroll = function () {
      scrollFunction();
    };
  },
  ...commonMethods
};
</script>
