<template>
  <div class="videoSliderMain" :class="{ visible: isVisible }">
    <!-- <video
      autoplay
      :muted="false"
      ref="videoPlayer"
      controls
      playsinline
      v-on:ended="closeVideo()"
      class="video-js"
      preload="auto"
      data-setup="{}"
    >
      <source
        type="video/mp4"
        ref="source"
        src="https://visyon360-website.s3.eu-west-1.amazonaws.com/0000_2D_Visyon_Showreel_Edit_v16.mp4"
      />
    </video> -->
    <video-player :options="videoOptions" />
    <a class="position-absolute close" @click="closeVideo">
      <img :src="require('@/assets/images/close-x.png')" alt="" />
    </a>
  </div>
</template>

<script>
import VideoPlayer from '@/components/commons/videojs/VideoPlayer.vue';
export default {
  components: {
    VideoPlayer
  },
  data: function () {
    return {
      showingIntro: true,
      isVisible: false,
      videoOptions: {
        autoplay: true,
        controls: true,
        sources: [
          {
            src:
              'https://visyon360-website.s3.eu-west-1.amazonaws.com/reel_v3.m3u8',
              type: 'application/x-mpegURL'
          }
        ]
      }
    };
  },
  methods: {
    closeVideo() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>


.videoSliderMain {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding-bottom: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  flex-direction: column;
}

.videoSliderMain video {
  width: 100%;
  height: calc( 100% - 140px);
  margin: auto;
}

.close {
  cursor: pointer;
  margin: auto;
  right: 2px;
  top: 2px
}
</style>
