<template>
  <div>
    <HeaderProject />
    <div class="container">
      <div class="row content">
        <div class="col-12 project-header d-flex align-items-center">
          <div class="project-header-content">
            <p class="mb-0">VIRTUAL ENVIRONMENT / LIVE EVENT</p>
            <h1>CUPRA Master<br/> Convention</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row content">
        <div class="col-12 px-0">
          <div class="video-container">
            <video height="720" width="1280" class="project-video" ref="videoPlayer" :poster="require('@/assets/images/project-cupra-1.png')">
              <source src="https://visyon360-website.s3.eu-west-1.amazonaws.com/VIDEO_CASE/CASE_CMC.mp4" type="video/mp4" class="w-100">
            </video>
            <img class="play-button" :src="require('@/assets/images/play-button.png')" v-on:click="playVideo"/>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row content">
        <div class="col-12 col-md-6">The Challenge</div>
        <div class="col-12 col-md-6">Although CUPRA is a digitally native brand, people are still the main focus of its strategy. For that reason, the first event held at the eGarage was entirely dedicated to its employees. Our challenge was to create a new learning experience to train 600 CUPRA Masters and help them to become the brand's top sales specialists.</div>
        <div class="col-12">
          <img :src="require('@/assets/images/project-cupra-2.png')" class="w-100 img-fluid" alt=""/>
        </div>
        <div class="col-12 col-md-6">The Story</div>
        <div class="col-12 col-md-6">With the aim of preparing the CUPRA Masters, we organised the CUPRA Master Convention: a virtual meeting where more than 600 people from 35 countries gathered to receive advanced interactive training.
          <br/><br/>
          The event was held in the auditorium of the CUPRA garage and was divided into two main parts. First, there was a live presentation with strategic speeches and masterclasses by the board members. A unique experience that combined mixed reality talk with social interactions and integrated xr elements in the scene.
          <br/><br/>
          In addition, we developed an augmented reality application that allowed users to see the CUPRA cars presented at the event and interact with them.
        </div>
        <div class="col-12 col-md-6">
          <img :src="require('@/assets/images/project-cupra-3.png')" class="w-100 img-fluid" alt=""/>
        </div>
        <div class="col-12 col-md-6">
          <img :src="require('@/assets/images/project-cupra-4.png')" class="w-100 img-fluid" alt=""/>
        </div>
        <div class="col-12 col-md-6 offset-md-6">The second part was one of the most awaited moments: the CUPRA Master challenge. An interactive real - time contest, where six hundred CUPRA Masters competed to show their skills and knowledge about the brand.
          <br/><br/>
          The contestants competed against each other and had to pass different tests to qualify for the next phase and reach the great final. A one-on-one challenge that was pure spectacle.
          <br/><br/>
          The CUPRA Master convention was an unprecedented success, with over a thousand participants from 35 countries and coverage in more than xxxx industry media.
        </div>
        <div class="col-12">
          <img :src="require('@/assets/images/project-cupra-5.png')" class="w-100 img-fluid" alt=""/>
        </div>
        <div class="col-12 col-md-6">
          <img :src="require('@/assets/images/project-cupra-6.png')" class="w-100 img-fluid" alt=""/>
        </div>
        <div class="col-12 col-md-6">
          <img :src="require('@/assets/images/project-cupra-7.png')" class="w-100 img-fluid" alt=""/>
        </div>
      </div>
    </div>
  </div>
  <ScrollTopButton />
</template>

<script>
import HeaderProject from "../components/Header/header.project.vue";
import ScrollTopButton from "../components/commons/scroll-top/ScrollTopButton";
export default {
  components: {
    HeaderProject,
    ScrollTopButton
  },
  mounted() {
    document.documentElement.style.scrollBehavior = "auto";
    setTimeout(() => window.scrollTo(0, 0), 5);
    setTimeout(
      () => (document.documentElement.style.scrollBehavior = "smooth"),
      5
    );
  },
  methods: {
    playVideo: function(e){
      this.$refs.videoPlayer.play();
      e.target.classList.add('d-none');
      this.$refs.videoPlayer.setAttribute("controls", "controls");
    }
  }
};
</script>

<style lang="scss" scoped>
.video-container{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .project-video{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .play-button{
    position: relative;
    cursor: pointer;
  }
}
.project-header{
  height: 70vh;
  .project-header-content{
    text-transform: uppercase;
    color: #f3f3f3;
    p{
      font-size: 16px;
      font-family: abc_diatypemedium, sans-serif;
    }
    h1{
      font-size: 50px;
      line-height: 70px;
      font-family: abc_diatypemedium, sans-serif;
      @media(min-width: 768px) {
        font-size: 88px;
        line-height: 96px;
      }
    }
  }
}
.content{
  font-size: 24px;
  line-height: 32px;
  > *{
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
</style>