<template>
  <div class="container block2 my-5" id="works">
    <div class="row">
      <div class="col-12 project p-0 p-sm-auto">
        <router-link :to="`/project/sorolla`">
          <div class="img-container">
            <img
              :src="require('@/assets/images/project-home-6.png')"
              class="w-100 img-transition"
              alt=""
            />
          </div>
          <div class="project-text-block px-4 px-sm-0">
            <span class="tags">VR | IMMERSIVE STORYTELLING</span>
            <h1 class="title">SOROLLA TRHOUGHT LIGHT</h1>
            <p class="description">
              Sorolla through light" is a multi-user Virtual Reality experience.
            </p>
          </div>
        </router-link>
      </div>
      
      <div class="col-12 col-md-6 project p-0 p-sm-auto">
        <router-link :to="`/project/cupra`">
          <div class="img-container">
            <img
              :src="require('@/assets/images/project-home-1.png')"
              alt=""
              class="w-100 img-fluid"
            />
          </div>
          <div class="project-text-block px-4 px-sm-0">
            <span class="tags">VIRTUAL ENVIRONMENT | LIVE EVENT</span>
            <h1 class="title">CUPRA Master Convention</h1>
            <p class="description">
              Turning 600 CUPRA Masters into the brand’s top sales experts thanks to an
              interactive learning experience.
            </p>
          </div>
        </router-link>
      </div>
      <div class="col-12 col-md-6 project p-0 p-sm-auto">
        <router-link :to="`/project/asics`">
          <div class="img-container">
            <img
              :src="require('@/assets/images/project-home-2.png')"
              alt=""
              class="w-100 img-fluid"
            />
          </div>
          <div class="project-text-block px-4 px-sm-0">
            <span class="tags">MR | VR</span>
            <h1 class="title">ASICS: Play the Unplayable</h1>
            <p class="description">
              A mixed reality experience that takes paddle to the next level.
            </p>
          </div>
        </router-link>
      </div>

      <div class="col-12 project p-0 p-sm-auto">
        <router-link :to="`/project/metahype`">
          <div class="img-container">
            <img
              :src="require('@/assets/images/project-home-3.png')"
              class="w-100 img-transition"
              alt=""
            />
          </div>
          <div class="project-text-block px-4 px-sm-0">
            <span class="tags">EXPANDING 3D ENVIRONMENT</span>
            <h1 class="title">Metahype</h1>
            <p class="description">
              An innovative experience that understands the potential of the new web 3.0.
            </p>
          </div>
        </router-link>
      </div>

      <div class="col-12 col-md-6 project p-0 p-sm-auto">
        <router-link :to="`/project/redbull`">
          <div class="img-container">
            <img
              :src="require('@/assets/images/project-home-4.png')"
              class="w-100 img-fluid"
              alt=""
            />
          </div>
          <div class="project-text-block px-4 px-sm-0">
            <span class="tags">VR</span>
            <h1 class="title">Red Bull BC One World Final</h1>
            <p class="description">
              Turning dance moves into 3D art with the power of virtual reality.
            </p>
          </div>
        </router-link>
      </div>

      <div class="col-12 col-md-6 project p-0 p-sm-auto">
        <router-link :to="`/project/girona`">
          <div class="img-container">
            <img :src="require('@/assets/images/project-home-5.png')" alt="" />
          </div>
          <div class="project-text-block px-4 px-sm-0">
            <span class="tags">AR | IMMERSIVE STORYTELLING</span>
            <h1 class="title">Girona Art Museum</h1>
            <p class="description">
              Enhancing children’s cultural experience through AR, play and immersive storytelling.
            </p>
          </div>
        </router-link>
      </div>
    </div>
    <div>
      <router-link to="/allprojects" class="link-projects">
        <div class="circle-projects">
          <div class="title mt-2">View all<br />projects</div>
          <svg
            class="arrow mt-2"
            width="27"
            height="21"
            viewBox="0 0 27 21"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g stroke="#F3F3F3" stroke-width="3" fill="none" fill-rule="evenodd">
              <path d="m15.5 2 8.5 8.5-8.5 8.5M24 10.5H0" />
            </g>
          </svg>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Block2",
  };
</script>

<style lang="scss" scoped>
  .block2 {
    color: #fff;
    margin-top: 5rem !important;
  }
  .block2 a {
    text-decoration: none;
    color: #fff;
  }
  .project {
    .project-text-block {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    font-family: abc_diatypemedium, sans-serif;
    .img-container {
      overflow: hidden;
      &:hover img {
        transform: scale(1.3);
      }
      img {
        transition: transform 0.5s ease-in-out;
      }

      .img-transition {
        &:hover {
          transform: scale(1.3) translate(10px, 51px);
        }
      }
    }
    & .tags {
      font-size: 14px;
      text-transform: uppercase;
    }

    & .title {
      margin-top: 15px;
      margin-bottom: 20px;
      font-size: 40px;
      line-height: 1;
      letter-spacing: -0.48px;
      text-transform: uppercase;

      @media (min-width: 400px) {
        font-size: 48px;
      }
    }

    & .description {
      font-size: 16px;
      letter-spacing: normal;
    }

    @media (min-width: 768px) {
      &:nth-child(2) {
        padding-right: 50px !important;
      }
      &:nth-child(3) {
        margin-top: 120px;
        padding-left: 50px !important;
      }
      &:nth-child(5) {
        margin-top: 120px;
        padding-right: 50px !important;
      }
      &:nth-child(6) {
        padding-left: 50px !important;
      }
    }

    img {
      width: 100%;
    }
  }
  .circle-projects {
    background-image: url("../../assets/images/circle-home.png");
    background-position: center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    margin: auto;
    position: relative;
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & .title {
      font-family: abc_diatypemedium;
      font-size: 24px;
      font-weight: 500;
      line-height: 1.33;
      letter-spacing: -0.4px;
      text-align: center;
      color: #fff;
    }
    .arrow {
      transition: 0.3s all ease-out;
    }
    &:hover .arrow {
      transform: translateX(50%);
    }
  }

  .link-projects {
    margin: 12%;
    display: block;
  }
</style>
