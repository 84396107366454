<template>
  <div>
    <HeaderProject />
    <div class="container">
      <div class="row content">
        <div class="col-12 project-header d-flex align-items-center">
          <div class="project-header-content">
            <p class="mb-0">VR | IMMERSIVE STORYTELLING</p>
            <h1>SOROLLA TRHOUGHT LIGHT</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row content">
        <div class="col-12 px-0">
          <div class="video-container">
            <img height="720" width="1280" class="w-100 h-auto" ref="videoPlayer" :src="require('@/assets/images/project-sorolla-1.png')">
            <!-- <video height="720" width="1280" class="project-video" ref="videoPlayer" :poster="require('@/assets/images/project-sorolla-1.png')">
              <source src="https://visyon360-website.s3.eu-west-1.amazonaws.com/VIDEO_CASE/CASE_SOROLLA.mp4" type="video/mp4" class="w-100">
            </video> -->
            <!-- <img class="play-button" :src="require('@/assets/images/play-button.png')" v-on:click="playVideo"/> -->
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row content">
        <div class="col-12 col-md-6">The Challenge</div>
        <div class="col-12 col-md-6">For some time now, museums have discovered a concept called "Immersive Culture", where the experience is collective, with freedom of movement where visitors interact with content that defies the laws of physics and shows content never exhibited before. Sorolla, an exceptional painter, challenged us to work on his treatment of light through new emerging technologies.</div>
        <div class="col-12">
          <img :src="require('@/assets/images/project-sorolla-2.png')" class="w-100 img-fluid" alt=""/>
        </div>
        <div class="col-12 col-md-6">The Story</div>
        <div class="col-12 col-md-6">Sorolla throught light is a multi-user Virtual Reality experience that takes visitors on a journey through Sorolla's time: more than 30 visitors are transported simultaneously, as if in a time tunnel, to the time of the Valencian painter from an unprecedented perspective thanks to virtual reality technology. Being able to move freely through Sorolla's original studio and walk along with Clotilde and María on the Malvarrosa beach, within his painting "Paseo a orillas del mar" (Walk along the seashore). 
        </div>
        <div class="col-12 col-md-6">
          <img :src="require('@/assets/images/project-sorolla-3.png')" class="w-100 img-fluid" alt=""/>
        </div>
        <div class="col-12 col-md-6">
          <img :src="require('@/assets/images/project-sorolla-4.png')" class="w-100 img-fluid" alt=""/>
        </div>
        <div class="col-12">
          <img :src="require('@/assets/images/project-sorolla-5.png')" class="w-100 img-fluid" alt=""/>
        </div>
      </div>
    </div>
  </div>
  <ScrollTopButton />
</template>

<script>
import HeaderProject from "../components/Header/header.project.vue";
import ScrollTopButton from "../components/commons/scroll-top/ScrollTopButton";
export default {
  components: {
    HeaderProject,
    ScrollTopButton
  },
  mounted() {
    document.documentElement.style.scrollBehavior = "auto";
    setTimeout(() => window.scrollTo(0, 0), 5);
    setTimeout(
      () => (document.documentElement.style.scrollBehavior = "smooth"),
      5
    );
  },
  methods: {
    playVideo: function(e){
      this.$refs.videoPlayer.play();
      e.target.classList.add('d-none');
      this.$refs.videoPlayer.setAttribute("controls", "controls");
    }
  }
};
</script>

<style lang="scss" scoped>
.video-container{
  // height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .project-video{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .play-button{
    position: relative;
    cursor: pointer;
  }
}
.project-header{
  height: 70vh;
  .project-header-content{
    text-transform: uppercase;
    color: #f3f3f3;
    p{
      font-size: 16px;
      font-family: abc_diatypemedium, sans-serif;
    }
    h1{
      font-size: 50px;
      line-height: 70px;
      font-family: abc_diatypemedium, sans-serif;
      @media(min-width: 768px) {
        font-size: 88px;
        line-height: 96px;
      }
    }
  }
}
.content{
  font-size: 24px;
  line-height: 32px;
  > *{
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
</style>