<template>
  <div class="filete w-100"></div>
  <nav
    class="
      navbar navbar-dark navbar-expand-lg
      fixed-top
      w-100
      d-none d-lg-flex
    " id="navbar"
  >
    <div class="container-fluid mx-4">
      <a class="navbar-brand" href="#"
        ><svg
          width="272"
          height="44"
          viewBox="0 0 272 64"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fill-rule="evenodd">
            <g fill="#FFF">
              <path
                d="m25.593 21.847 5.189 14.352c.376 1.026.666 1.882.84 2.539.146-.628.435-1.484.812-2.54l5.217-14.351h4.146L33.97 41.82h-4.899l-7.71-19.973h4.232zM65.713 41.82h3.797V21.847h-3.797zM98.153 34.972c.087 1.34.638 2.368 1.624 3.082.956.74 2.145 1.112 3.565 1.112 2.434 0 4.058-.914 4.058-2.74 0-1.483-1.102-2.339-3.334-2.767l-3.45-.657c-3.391-.655-5.681-2.596-5.681-5.648 0-2.312 1.42-4.224 3.856-5.25 1.188-.514 2.55-.77 4.058-.77 2.493 0 4.435.598 5.855 1.769 1.42 1.198 2.175 2.796 2.29 4.793h-3.942c-.087-1.97-1.566-3.395-4.435-3.395-2.29 0-3.884.941-3.884 2.538 0 1.456 1.101 2.026 3.42 2.483l3.42.657c3.392.655 5.654 2.539 5.654 5.706 0 3.767-2.928 6.448-8.059 6.448-2.696 0-4.812-.657-6.378-1.997-1.565-1.314-2.434-3.11-2.58-5.364h3.943zM133.463 21.847h4.551l4.058 6.848c.435.684.782 1.312 1.043 1.882.174-.37.522-.997 1.015-1.882l4.117-6.848h4.376l-7.68 11.869v8.104h-3.827v-8.104l-7.653-11.87zM184.107 39.166c3.623 0 6.117-2.682 6.117-7.333 0-4.45-2.494-7.332-6.117-7.332-3.595 0-6.029 2.823-6.029 7.332 0 4.593 2.434 7.333 6.03 7.333m-5.103-16.55c1.507-.855 3.218-1.283 5.102-1.283 3.797 0 6.958 1.826 8.726 4.994.87 1.596 1.304 3.424 1.304 5.506 0 4.337-1.855 7.59-4.87 9.244-1.537.856-3.247 1.256-5.16 1.256-5.739 0-9.942-3.908-9.942-10.5 0-4.194 1.825-7.476 4.84-9.216M219.214 21.847h5.711l8.754 15.036c-.087-.77-.117-1.77-.117-2.996v-12.04h3.799V41.82h-4.986l-9.479-16.292c.087.77.115 1.683.115 2.796V41.82h-3.797V21.847z"
              />
            </g>
            <g fill="#DF3A13" fill-rule="nonzero">
              <path
                d="M270.895 12.076c1.602 1.566 1.304 3.277.113 4.48l-4.55 4.442c-1.266 1.2-2.983 1.493-4.589-.073a3.498 3.498 0 0 1-.669-.803l3.616-3.6c1.03-.974 1.372-2.3.626-3.595h.01l-.029-.044.884-.868c1.27-1.218 2.985-1.505 4.588.061z"
              />
              <path
                d="m265.4 12.925-3.79 3.559c-1.085.982-1.436 2.33-.62 3.638l-.934.876c-1.317 1.2-3.1 1.493-4.773-.074a3.576 3.576 0 0 1-.683-.78l.024-.022a.054.054 0 0 1-.013-.025l3.773-3.542c1.084-.984 1.436-2.332.622-3.64l.931-.913c1.32-1.2 3.103-1.493 4.773.074.253.241.477.508.668.794.005.022.011.039.022.055zM258.8 12.902l-3.617 3.574c-1.043.975-1.374 2.315-.609 3.619l-.025.024.015.023-.872.854c-1.268 1.202-2.983 1.495-4.589-.073-1.606-1.569-1.306-3.282-.075-4.488l4.512-4.414c1.268-1.238 3.022-1.493 4.588.037.256.256.482.539.672.844z"
              />
            </g>
          </g>
        </svg>
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarText"
        aria-controls="navbarText"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div>
        <div class="collapse navbar-collapse" id="navbarText">
          <!-- <div class="lang-display">
            <language-selector />
          </div> -->
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link" aria-current="page" href="#works">{{
                $t("works")
              }}</a>
              <!-- <a class="nav-link active" aria-current="page" href="#">Works</a> -->
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="#about">About us</router-link>
              <!-- <a class="nav-link" href="#">About us</a> -->
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="#contact">Contact</router-link>
              <!-- <a class="nav-link" href="#">Contact</a> -->
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
// import LanguageSelector from "../commons/language-selector/selector.content.vue";

export default {
  name: "Header",
  props: {},
  components: {
    // LanguageSelector,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.navbar {
  transition: all 0.2s linear;
}
.nav-item {
  display: inline-block;
  text-align: left;
  margin-right: 32px;
}
.nav-item:last-of-type {
  margin-right: 0px;
}
.navbar-dark .navbar-nav .nav-link, 
.navbar-dark .navbar-nav .nav-link:hover {
  color: #F3F3F3;
  font-size: 24px;
  letter-spacing: -0.6px;
  a {
    padding-left: 0;
    padding-right: 0;
  }
  
}
.filete {
  height: 8px;
  background-image: linear-gradient(to left, #ff5848 1%, #ff57c6 99%);
}
</style>
