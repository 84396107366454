<template>
  <div
    class="
      d-flex
      justify-content-between
      px-sm-5
      py-4
      text-light
      bg-dark
      footer-links
    "
  >
    <div class="footer-list d-none d-sm-flex">
      <div class="link">© Copyright 2022&nbsp;&nbsp;|&nbsp;&nbsp;</div>
      <router-link class="link" to="/privacypolicy" @click="openPrivacy"
        >Privacy Policy&nbsp;&nbsp;|&nbsp;&nbsp;</router-link
      >
      <router-link class="link" to="/cookiespolicy" @click="closePrivacy">Cookies Policy</router-link>
    </div>
    <div class="d-block d-sm-none">
      <div class="footer-list d-flex flex-column">
        <div class="link">© Copyright 2022</div>
        <router-link class="link" to="/privacypolicy"
          >Privacy Policy</router-link
        >
        <router-link class="link" to="/cookiespolicy"
          >Cookies Policy</router-link
        >
      </div>
    </div>
    <div class="footer-logo">
      <img :src="require('@/assets/images/footer-icon.png')" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openPrivacy() {
     const appElement = document.querySelector("#app")
     appElement.classList.add("height-100","h-auto")
    },
    closePrivacy() {
      const appElement = document.querySelector("#app").classList;
      appElement.contains("height-100") ? appElement.remove("height-100") : true;
    }
  }
};
</script>

<style lang="scss" scoped>
.footer-links {
  padding-left: 30px;
  padding-right: 30px;
}
.footer-list {
  .link {
    font-family: abc_diatypemedium;
    font-size: 14px;
    letter-spacing: -0.35px;
    opacity: 0.5;
    text-decoration: none;
    color: #fff;
  }
}
</style>