import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import Projects from "@/views/Projects.vue";
import ProjectCupra from "@/views/ProjectCupra.vue";
import ProjectAsics from "@/views/ProjectAsics.vue";
import ProjectMetahype from "@/views/ProjectMetahype.vue";
import ProjectRedbull from "@/views/ProjectRedbull.vue";
import ProjectGirona from "@/views/ProjectGirona.vue";
import ProjectSorolla from "@/views/ProjectSorolla.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import CookiesPolicy from "@/views/CookiesPolicy.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/allprojects",
    name: "Projects",
    component: Projects,
  },
  {
    path: "/privacypolicy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/cookiespolicy",
    name: "CookiesPolicy",
    component: CookiesPolicy,
  },
  // {
  //   path: "/project/:id",
  //   name: "ProjectDescription",
  //   component: ProjectDescription,
  // },
  {
    path: "/project/cupra",
    name: "ProjectCupra",
    component: ProjectCupra,
  },
  {
    path: "/project/asics",
    name: "ProjectAsics",
    component: ProjectAsics,
  },
  {
    path: "/project/metahype",
    name: "ProjectMetahype",
    component: ProjectMetahype,
  },
  {
    path: "/project/redbull",
    name: "ProjectRedbull",
    component: ProjectRedbull,
  },
  {
    path: "/project/girona",
    name: "ProjectGirona",
    component: ProjectGirona,
  },
  {
    path: "/project/sorolla",
    name: "ProjectSorolla",
    component: ProjectSorolla,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { el: to.hash }
    }
  },
});

export default router;
