<template>
   <div class="position-fixed scroll-to-top"   id="buttonToTop">
    <img @click="scrollToTop" :src="require('@/assets/images/back-to-top.png')" alt="back-to-top"/>
  </div>
</template>

<script>
    export default {
       methods: {
        scrollToTop() {
          window.scrollTo(0, 0);
        },
      },
    }
</script>

<style lang="scss" scoped>
.scroll-to-top{
  bottom: 80px;
  right: 10px;
}
img{
  cursor: pointer;
  bottom: 10px;
  right: 10px;
}
</style>