<template>
  <div>
    <div class="d-none d-sm-block">
      <HeaderProject />
    </div>
    <div class="d-block d-sm-none">
      <HeaderMobile />
    </div>
    <div class="w-100">
      <div class="container-fluid pt-5 privacy">
        <div class="row content">
          <div class="col-12 col-md-6 title">Privacy policy</div>
          <div class="col-12 col-md-6 space-right space-bottom">
            <p>
              Estudio Bay, S.L.U (hereinafter “VISYON”) with N.I.F. B65684813
              and registered address at c/ Lepanto, 350, 8ª planta, 08025
              Barcelona, inform you that your personal data will be processed to
              keep you up to speed with our latest activities and productions
              through our newsletter, the organization of workshops and solving
              queries you may have.
            </p>

            <p>
              The legal basis for this processing is your consent which you can
              withdraw at any time. However, it will not affect the validity of
              the processing carried out before its withdrawal.
            </p>
            <p>
              The time of conservation of the subscribers’ data will be subject
              to the unsubscription of the user to the mailing list. In all
              other cases, your data will be processed until it fulfils the
              purpose for which it was collected.
            </p>
            <p>
              For the purposes described, the personal data may be communicated
              to the rest of the companies of Mediapro Group to which VISYION
              belongs to (including those available on the following websites:
              <a
                href="http://entidades.imagina-media.com/www.mediapro.tv"
                class="link"
                >http://entidades.imagina-media.com/www.mediapro.tv</a
              >) including those located in countries that do not offer a
              similar level of data protection as in the EEA.
            </p>
            <p>
              You may exercise your rights of access, rectification,
              cancellation, opposition and, where appropriate, the portability
              and restriction of the processing by contacting the Data
              Protection Officer at Av. Diagonal 177-183, 08018, Barcelona or
              email address:
              <a class="link" href="mailto:dpd@mediapro.tv">dpd@mediapro.tv</a>
              (this email address is only appropriated for data protection
              inquires), with the reference “Data Protection”, indicating the
              request. Notwithstanding, requests that are particularly unfounded
              or excessive may be rejected. In addition, the data subject may
              file a complaint before the supervisory authority.
            </p>
          </div>
        </div>
      </div>
    </div>
    <FooterLinks />
  </div>
</template>

<script>
import HeaderProject from "../components/Header/header.project.vue";
import HeaderMobile from "../components/Header/header.mobile.vue";
import FooterLinks from "../components/Footer/FooterLinks.vue";
export default {
  components: {
    HeaderProject,
    HeaderMobile,
    FooterLinks,
  },
  mounted() {
    const appElement = document.querySelector("#app")
    appElement.classList.add("height-100","h-auto")
    document.documentElement.style.scrollBehavior = "auto";
    setTimeout(() => window.scrollTo(0, 0), 5);
    setTimeout(
      () => (document.documentElement.style.scrollBehavior = "smooth"),
      5
    );
  },
};
</script>

<style lang="scss" scoped>
.content {
  //   padding-top: 40px;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.18px;
  font-family: abc_diatypemedium, sans-serif;

  > * {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .title {
    font-size: 36px;
    line-height: 1.33;
    letter-spacing: -0.4px;
    padding-left: 38px;
    @media (max-width: 767px) {
      padding-left: initial;
    }
  }
  .link {
    color: #ff56c6;
    text-decoration: none;
  }
}
.privacy {
  margin-top: 10vh;

  padding-left: 30px;
  padding-right: 30px;
  .space-right {
    padding-right: 38px;
  }
  .space-bottom {
    margin-bottom: 111px;
  }
  @media (max-width: 768px) {
    .space-right {
      padding: 0;
    }
  }
}
.footer-links {
  position: fixed;
  bottom: 0;
  width: 100%;
}
</style>